import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { GalleryComponent, GalleryItem } from '@daelmaak/ngx-gallery';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import { PriceTagComponent } from '../../amm-share/components/price-tag/price-tag.component';
import { VariationGroupComponent } from '../../amm-share/components/variation-group/variation-group.component';
import { forceLogin } from '../../amm-share/decorators';
import { GalleryConfig } from '../../amm-share/models';
import {
  AmmPrice,
  Image,
  Product,
  VariantCombinationBackend,
  VariantSelectionBackend,
} from '../../amm-share/models/catalog.model';
import { ProductService } from '../../amm-share/services';
import { ProductStore, ShoppingInfoStore } from '../../amm-share/stores';
import { AmmQtyAdjusterComponent } from './../../amm-share/components/amm-qty-adjuster/amm-qty-adjuster.component';
import { AmmDialogService } from './../../amm-share/services/amm-dialog-service.service';
import { StorageService } from './../../amm-share/services/storage.service';

@Component({
  selector: 'amm-product-quick-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    VariationGroupComponent,
    AmmQtyAdjusterComponent,
    GalleriaModule,
    PriceTagComponent,
    GalleryComponent,
  ],
  templateUrl: './product-quick-view.component.html',
  styleUrl: './product-quick-view.component.scss',
})
export class ProductQuickViewComponent implements OnInit {
  public product: Product | undefined;
  public medias!: GalleryItem[];
  galleryConfig: GalleryConfig = {
    arrows: true,
    descriptions: false,
    mouseGestures: true,
    touchGestures: true,
    counter: false,
    counterOrientation: 'bottom',
    visibleItems: 1,
    objectFit: 'cover',
    loading: 'lazy',
    loop: true,
    rtl: false,
    selectedIndex: 0,
    thumbs: true,
    thumbsAutoScroll: true,
    thumbsOrientation: 'bottom',
    thumbsArrows: false,
    thumbsArrowSlideByLength: 0,
    thumbsScrollBehavior: 'smooth',
  };
  public variantCombination: VariantCombinationBackend | null = null;
  public shopQty: number = 0;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  public lowestPrice: AmmPrice = {
    currency: 'USD',
    sign: '$',
    amount: 0,
  };
  public vairants: VariantSelectionBackend | null = null;

  public shoppingCartInfoStore = inject(ShoppingInfoStore);
  public productStore = inject(ProductStore);
  private router: Router = inject(Router);
  private dialogConfig = inject(DynamicDialogConfig<ProductQuickViewComponent>);
  private dialogRef = inject(DynamicDialogRef<ProductQuickViewComponent>);
  private dialogService = inject(DialogService);
  private ammDialogService = inject(AmmDialogService);
  private productService = inject(ProductService);
  private storageService = inject(StorageService);
  ngOnInit(): void {
    this.product = this.dialogConfig?.data?.product;
    this.productService
      .getProductsByFamilyCode(this.product?.code || '')
      .subscribe((products: Product[]) => {
        if (products.length > 0) {
          const variantProduct = products[0];
          console.log(variantProduct);
          this.variantCombination =
            variantProduct.variantCombinationMap || null;
          this.lowestPrice = variantProduct.variantMinPrice || {
            currency: 'USD',
            sign: '$',
            amount: 0,
          };
          this.vairants = variantProduct.variants || null;
          if (variantProduct.mainImage) {
            const imageCollections: Image[] = [variantProduct.mainImage];
            if (variantProduct.variants) {
              const values = Object.values(variantProduct.variants);

              values.forEach((x) => {
                if (x.mainImage) {
                  imageCollections.push(x.mainImage);
                }
                if (x.merchImages && x.merchImages.length > 0) {
                  imageCollections.concat(x.merchImages);
                }
              });
            }

            this.medias = imageCollections.map(
              (x) =>
                <GalleryItem>{
                  src: x.uri,
                  thumbSrc: x.uri,
                },
            );
          }
        }
      });
  }
  onViewDetailClick() {
    this.router.navigate(['/details', this.product?.id]).then(() => {
      this.dialogRef.close();
    });
  }
  onAddToCartClick() {
    // call to add the to shopping cart
    if (this.product) {
      this.shoppingCartInfoStore.setVariantShoppingCart(
        this.product.id,
        this.shopQty,
        this.product.code || '',
      );
    }
  }
  onIncrease(qty: number) {
    this.shopQty = qty;
  }
  onDecrease(qty: number) {
    this.shopQty = qty;
  }

  onAddToFavoriteClick() {
    this.AddFavorite(
      this.storageService,
      this.ammDialogService,
      this.dialogService,
    );
  }
  @forceLogin('buyerInfo')
  AddFavorite(
    storageService: StorageService,
    ammDialogService: AmmDialogService,
    dialogService: DialogService,
  ) {
    if (this.product) {
      this.shoppingCartInfoStore.increaseFavorite(this.product?.id);
    }
  }
}
