<div class="flex flex-row h-full quick-product-view-body">
  <div class="pic-gallary-container h-full">
    <gallery
      [items]="medias"
      [counter]="galleryConfig.counter"
      [loop]="galleryConfig.loop"
      [thumbsArrows]="galleryConfig.thumbsArrows"
      [thumbsAutoScroll]="galleryConfig.thumbsAutoScroll"
    ></gallery>
  </div>
  <div class="flex flex-col right-section h-full">
    <div class="flex flex-col variant-section">
      <div class="flex flex-col product-header">
        <div>
          {{ product?.title }}
        </div>
        <div>{{ product?.store?.storeName }}</div>
      </div>
      <div>
        <amm-variation-group
          [variantCombination]="variantCombination"
        ></amm-variation-group>
      </div>
    </div>
    <div class="flex flex-col action-section">
      <div class="flex flex-row gap-4 price-section">
        <amm-price-tag
          [price]="lowestPrice.amount || 0"
          [currencySign]="lowestPrice.sign || '$'"
        ></amm-price-tag>
        <div
          class="flex gap-1 items-center view-detail-btn ml-auto cursor-pointer"
          (click)="onViewDetailClick()"
        >
          <span>View detail</span>
          <span class="material-symbols-outlined"> arrow_forward </span>
        </div>
      </div>
      <div class="flex flex-row gap-2 cart-operation-section">
        <!--
          Check if the product combination exists or not
          if product exists then add to cart
          otherwise show add to favorite button
        -->
        @if (false) {
          <div
            class="add-to-cart-btn cursor-pointer"
            (click)="onAddToFavoriteClick()"
          >
            <span class="material-symbols-outlined"> favorite </span>
            <span>Add to favorite</span>
          </div>
        } @else {
          <div class="qty-adjuster-wrapper">
            <amm-qty-adjuster
              [showTitleIcon]="false"
              [shopQty]="shopQty"
              (onIncrease)="onIncrease($event)"
              (onDecrease)="onDecrease($event)"
            ></amm-qty-adjuster>
          </div>
          <div
            class="add-to-cart-btn cursor-pointer"
            (click)="onAddToCartClick()"
          >
            Add to cart
          </div>
        }
      </div>
    </div>
  </div>
</div>
