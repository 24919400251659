import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { VariantCombinationBackend } from '../../models/catalog.model';
import { ProductStore } from '../../stores';

@Component({
  selector: 'amm-variation-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './variation-group.component.html',
  styleUrl: './variation-group.component.scss',
})
export class VariationGroupComponent {
  public variantCombination = input<VariantCombinationBackend | null>();

  public productStore = inject(ProductStore);

  onVariantSelection(type: string, value: string) {
    // Emit out selection
    const currentSelection =
      this.productStore.variantSelection().get(type) || 0;
    if (currentSelection === value) {
      this.productStore.setVariantSelection(type, '');
    } else {
      this.productStore.setVariantSelection(type, value);
    }
  }
  getKeys(variantCombination: VariantCombinationBackend | null | undefined) {
    if (variantCombination) {
      return Object.keys(variantCombination);
    }
    return [];
  }
  getValues(
    variantCombination: VariantCombinationBackend | null | undefined,
    key: string,
  ) {
    if (variantCombination) {
      return variantCombination[key];
    }
    return [];
  }
}
