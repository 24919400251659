@if (hasInventory()) {
  @if(!selected()) {
    <div class="flex flex-col justify-between">
      <span>{{
        currencySign() + (price() | number: priceFormat()) + vairantIndicator()
      }}</span>
      <span class="variant-intro-text">{{ hasInventoryText() }}</span>
    </div>
  } @else {
    <div class="flex flex-col justify-between">
      <span>{{
        currencySign() + (price() | number: priceFormat())
      }}</span>
      <span class="variant-intro-text">{{ onVariantSelectedText()}}</span>
    </div>
  }
} @else if (isOutOfStock()) {
  <div class="flex gap-2 justify-between">
    <span class="strike-text">{{
      currencySign() + (price() | number: priceFormat())
    }}</span>
    <span class="none-available-text">{{ outOfStockText() }}</span>
  </div>
} @else if (isNoLongerAvailable()) {
  <div class="flex justify-between">
    <span class="none-available-text">{{ noLongerAvailableText() }}</span>
  </div>
}
