@for (key of getKeys(variantCombination()); track $index) {
  <div class="flex flex-col items-start variation-group">
    <div>{{ key }}</div>
    <div class="variation-value-wrapper">
      @for (value of getValues(variantCombination(), key); track $index) {
        <div
          class="variation-value-item cursor-pointer"
          (click)="onVariantSelection(key, value)"
          [ngClass]="{
            'variation-value-item-selection':
              productStore.variantSelection().get(key) == value,
          }"
        >
          {{ value }}
        </div>
      }
    </div>
  </div>
}
